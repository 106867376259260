






































































































































































































































































































































































































.card-box {
  margin-bottom: 10px;
  height: 20%;
      display: flex;
    overflow: hidden;
    justify-content: space-between;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 16%;
    // min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      // line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../../assets/six.png);
    background-size: 100% 100%;
  }
  & > :nth-child(2) {
    background-image: url(../../../assets/first.png);
    background-size: 100% 100%;
  }
  & > :nth-child(3) {
    background-image: url(../../../assets/second.png);
    background-size: 100% 100%;
  }
  & > :nth-child(4) {
    background-image: url(../../../assets/third.png);
    background-size: 100% 100%;
  }
  & > :nth-child(5) {
    background-image: url(../../../assets/fourth.png);
    background-size: 100% 100%;
  }
  & > :nth-child(6) {
    background-image: url(../../../assets/five.png);
    background-size: 100% 100%;
  }
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  .btn-box {
    align-self: flex-end;
    padding: 10px;
    // margin-bottom: 10px;
    .el-button {
      &:focus {
        background-color: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
    .active {
      background-color: rgb(218, 221, 250);
      color: rgb(92, 107, 232);
      border-color: rgb(218, 221, 250);
    }
  }
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
